import { navigate } from "gatsby";
import { isBrowser } from "../utils/helpers";

const VerifiedCallback = () => {
  const url = isBrowser ? window.location.href : '';
  const messageLine = url.split('&').find((line) => line.startsWith('message='));
  const message = messageLine && messageLine.split('=')[0];

  if (isBrowser) {
    navigate('/');
  }
  
  return message || "Verifying user...";
};

export default VerifiedCallback;
